<template>
  <div class="box">
    <div class="flex">
      <el-button @click="handleSelectFile" size="small" type="primary" icon="el-icon-upload2">上传</el-button>
    </div>
  </div>
</template>

<script>
import { handleChooseFile } from "@/utils";
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表"}}},
  methods: {
    handleSelectFile() {
      handleChooseFile({
        api: '/zhaosheng/api/recruit-2024/offline/gen-student-order-formalinformation',
        show: true,
        onSuccess: () => {this.$message.success('操作成功')}
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.box {
  padding-top: 30rem;
  padding-left: 30rem;
}
</style>
